import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  HashRouter, Redirect, Route, Switch,
} from 'react-router-dom';

import store from './store';
import './scss/style.scss';

const Layout = React.lazy(() => import('./containers/Layout'));
const Login = React.lazy(() => import('./pages/Login'));

const App = () => {
  const [authenticated, setAuthenticated] = useState(false);
  return (
    <Provider store={store}>
      <HashRouter>
        <React.Suspense
          fallback={(
            <div className="pt-3 text-center">
              <div className="sk-spinner sk-spinner-pulse" />
            </div>
        )}
        >
          <Switch>
            {!authenticated && <Route exact path="/" name="Login Page" render={(props) => <Login {...props} onLogin={(data) => setAuthenticated(data)} />} />}
            {authenticated && <Route path="/" name="Home" render={(props) => <Layout {...props} />} />}
            {/* <Route path="/" name="Home" render={(props) => <Layout {...props} />} /> */}
            <Redirect to="/" />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </Provider>
  );
};

ReactDOM.render(
  <App />,
  document.getElementById('base'),
);
